// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";



img {
    max-width: 100% !important;
    height: auto !important;
}
.detial-item {
    border:1px solid #d2d2d2;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
}
.detial-item h2 {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #d2d2d2;
}
.detial-item img {
    width: 150px;
    height: 150px !important;
    object-fit: contain;
}
