// Here you can add other styles

.btn-primary{
    color: #fff;
    background-color: #D06801;
    border-color: #D06801;
}

.btn-primary:hover {
	color: #fff;
	background-color: #7D0005;
    border-color: #7D0005;
}

.btn-primary:focus, .btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active{
    background-color: #7D0005;
    border-color: #7D0005;
    box-shadow: none;
}

.c-sidebar{
    background-color: rgba(255,255,255,1);
}

.c-sidebar .c-sidebar-nav-link{
    color: #333;
    letter-spacing: 0.3px;
    font-weight: 600;
}
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-link.c-active{
    background: #D06801;   
}

.c-sidebar .c-sidebar-nav-link.c-active{
    font-size: 18px;
}
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon{
    font-size: 24px;
}
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon{
    color: #4a4a4a;
}

.brand{
    background: #fff;
    border-right: 1px solid lightgray;
    height: 104px;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.brand-logo .default{
    max-width: 190px !important;
    width: 100%;
}
.brand-logo .small{
    display: none;
}
.c-sidebar.c-sidebar-minimized .brand-logo .default{
    display: none;
}
.c-sidebar.c-sidebar-minimized .brand-logo .small{
    display: block;
}

span.error {
    color: red;
}

.profile_img img {
    border-radius: 50%;
    border: 5px solid #ccc;
    width: 150px;
    height: 150px;
}

img {
    vertical-align: middle;
}

.image-card {
    max-width: 100%;
    width: 120px;
}

  #lightbox-img {
    max-width: 100%;
  }

  #lightbox{
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background-color: transparent;
  }
  
.lightbox-container {
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

.c-subheader .breadcrumb-item a{
    color: #D06801;
}

.page-link{
    color: #D06801;
}
.page-link:hover{
    color: #D06801;
}
.page-item.active .page-link{
    background-color: #D06801;
    border-color: #D06801;
}
.c-sidebar .c-sidebar-minimizer{
    background: #fdd99e;
}

.c-sidebar .c-sidebar-minimizer:hover{
    background: #fdd99e;
}

.modal-header .btn-close{
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border:0;
    background-image: url('../assets/icons/close.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
}

span.error.variation_error {
    margin-left: 190px;
}

input.form-control.col-sm-3 {
    margin-bottom: 10px;
}

.checkbutton {position: relative;}
.checkbutton [type="checkbox"]{display: none;}
.checkbutton label {
    margin: 0;
    background: #D06801;
    color: #fff;
    padding: 0 0.5rem;
    font-size: 0.765625rem;
    line-height: 33px;
    border-radius: 0.2rem;
    cursor: pointer;
}

.checkbutton [type="checkbox"]:checked + label {
    background: green;
}
.fw-bolder {font-weight: 700;}
.fw-lighter {font-weight: 100;}

.currencyPlatform {padding: 0;}
.currencyPlatform label {margin: 0; padding: 4px 11px; font-size: 14px;}
.currencyPlatform input {width: 70%;}

.myform h2 {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 15px;
    color: #000;
}
.dashbordBlock {
    background-color: #FFB6C1;
}
.dashbordBlock1 {
    background-color: #87CEFA;
}
.dashbordBlock2 {
    background-color: #B0C4DE;
}
.dashbordBlock3 {
    background-color: #FFE4B5;
}
.dashbordBlock4 {
    background-color: #AFEEEE;
}
.dashbordBlock5 {
    background-color: #FFDAB9;
}
.dashbordBlock6 {
    background-color: #C0C0C0;
}
.dashbordBlock7 {
    background-color: #F0E68C;
}
.dashbordBlock8 {
    background-color: #E0FFFF;
}
.table td
{
    cursor: auto;
}